const theme = {
  colors: {
    text: "#2A2A2D",
    background: "#ffffff",
    lightBackground: "#fafafa",
    primary: "#A30008",
    overlayPrimary: "rgba(163,0,8,.5)",
    secondary: "#A30008",
    light: "#ffffff",
    dark: "#131E26",
    lightGrey: "#f3f3f3",
    grey: "#E5E5E5",
  },
  fonts: {
    body: 'Assistant, system-ui, -apple-system, BlinkMacSystemFont, Arial, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading:
      'Assistant, system-ui, -apple-system, BlinkMacSystemFont, Arial, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  },
  space: [0, 4, 8, 16, 24, 32, 48, 64, 96, 128, 192, 256, 512],
  fontSizes: [12, 14, 16, 20, 24, 32, 40, 60, 96, 128, 192, 256, 512],
  fontWeights: {
    body: 400,
    heading: 700,
  },
  lineHeights: {
    body: "22px",
    heading: ["40px","40px","40px","60px"],
  },
  sizes: {
    container: 1200,
  },
  radii: {
    none: "0",
    xs: ".25rem",
    sm: ".5rem",
    md: "1rem",
    lg: "2rem",
    full: "9999px",
  },
  shadows: {
    none: "none",
    default:
      "0 20px 40px -10px rgba(50,50,93,0.15),0 10px 30px -20px rgba(0,0,0,0.15)",
  },
  text: {
    underPageTitle: {
      fontSize: ["65px",8,9,10],
      position: "absolute",
      color: "transparent",
      zIndex: "0",
      top: ["5%","10%","10%","10%"],
      transform: "translateY(-55%)",
      opacity: ".25",
      transition: "1500ms cubic-bezier(.23,1,1,1) all",
      pointerEvents: "none",
      display: "block",
      letterSpacing: "-.04em",
      userSelect: "none",
      whiteSpace: "nowrap",
      "-webkit-text-stroke-color": "white",
      "-webkit-text-stroke-width": "1.44px",
    },
    default: {
      lineHeight: "body",
    },
    heading: {
      fontSize: [5],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "text",
    },
    h1: {
      fontSize: [6, 7],
      fontFamily: "heading",
      lineHeight: ["40px","60px","60px","60px"],
      fontWeight: "heading",
      color: "text",
      mb: 3,
    },
    h1home: {
      fontSize: [6, "70px"],
      letterSpacing: "2.1px",
      fontFamily: "heading",
      lineHeight: ["40px","60px","70px","70px"],
      fontWeight: "heading",
      color: "text",
      mb: 3,
    },
    h2: {
      fontSize: [6, 7],
      fontFamily: "heading",
      lineHeight: ["40px","60px","60px","60px"],
      fontWeight: "heading",
      color: "text",
      mb: 3,
      mt: 4,
    },
    h3: {
      fontSize: [6],
      fontFamily: "heading",
      lineHeight: ["40px","60px","40px","40px"],
      fontWeight: "heading",
      mb: 3,
      mt: 4,
    },
    h4: {
      fontSize: [4],
      fontWeight: "bold",
      color: "text",
      mb: 2,
      mt: 4,
    },
    h5: {
      fontSize: [3],
      color: "dark",
      fontWeight: "body",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },
    h6: {
      fontSize: [2],
      color: "dark",
      fontWeight: "body",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },
    kicker: {
      fontSize: 3,
      fontWeight: "regular",
      letterSpacing: "4px",
      lineHeight: "27px",
      textTransform: "uppercase",
      color: "primary",
    },
    article: {
      fontSize: 3,
      lineHeight: 1.5,
    },
  },
  layout: {
    container: {
      padding: [3, 4],
    },
    xs: {
      maxWidth: "576px",
    },
    sm: {
      maxWidth: "576px",
    },
    md: {
      maxWidth: "768px",
    },
    lg: {
      maxWidth: "992px",
    },
    xl: {
      maxWidth: "1200px",
    },
    xxl: {
      maxWidth: "1400px",
    },
    fw: {
      maxWidth: "100%",
    },
    header: {
      maxWidth: "100%",
    },
  },
  inputs: {
    primary: {
      outline: 0,
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      borderRadius: "none",
      borderColor: "grey",
      ":focus": {
        borderColor: "primary",
      },
    },
  },
  buttons: {
    full: {
      primaryArrow: {
        display: "flex",
        "&:hover": {
          "span:nth-child(2)": {
            svg: {
              zIndex: 2,
              stroke: "light",
            },
            "&:after": {
              color: "light",
              transform: "scaleY(1)",
              transformOrigin: "bottom",
            },
          },
        },
        "span:nth-child(1)": {
          py: [2],
          px: [3],
          backgroundColor: "primary",
          color: "light",
          minHeight: "50px",
          display: "flex",
          justiContents: "center",
          alignItems: "center",
        },
        "span:nth-child(2)": {
          py: [2],
          px: [2],
          width: "50px",
          height: "50px",
          backgroundColor: "light",
          border: "1px solid",
          borderColor: "primary",
          color: "primary",
          minHeight: "50px",
          display: "flex",
          justifyContent: "center",
          justifyItems: "center",
          alignItems: "center",
          transition: "300ms ease all",
          position: "relative",
          overflow: "hidden",
          "&:after": {
            content: "''",
            display: "block",
            position: "absolute",
            left: "-1px",
            top: "-1px",
            right: "-1px",
            bottom: "-1px",
            zIndex: 1,
            backgroundColor: "primary",
            transform: "scaleY(0)",
            transformOrigin: "top",
            transition: "300ms ease transform",
          },
        },
      },
    },
    primary: {
      cursor: "pointer",
      color: "light",
      bg: "primary",
      "&:hover": {
        bg: "dark",
      },
    },
    secondary: {
      color: "background",
      bg: "secondary",
    },
  },
  links: {
    mobileNav: {
      color: "light",
      lineHeight: "body",
      "&:hover": {
        textDecoration: "none",
      },
      "&.active": {
        "&:before": {
          content: "''",
          display: "block",
          backgroundColor: "text",
          width: [2],
          height: [2],
          left: [0],
          position: "absolute",
          zIndex: -1,
          top: "50%",
          transform: "translateY(-50%)",
          width: "2.5rem",
          height: "2.5rem",
          transition: "width .4s",
        },
        "&:hover": {
          "&:before": {
            width: "100%",
          },
          color: "light",
        },
      },
      "&:before": {
        content: "''",
        display: "block",
        backgroundColor: "primary",
        width: [2],
        height: [2],
        position: "absolute",
        zIndex: -1,
        top: "50%",
        left: [0],
        transform: "translateY(-50%)",
        width: "0rem",
        height: "2.5rem",
        transition: "width .4s",
      },
      "&:hover": {
        "&:before": {
          width: "100%",
        },
      },
    },
    nav: {
      color: "light",
      "&:hover": {
        textDecoration: "none",
      },
      "&.active": {
        "&:before": {
          content: "''",
          display: "block",
          backgroundColor: "primary",
          width: [2],
          height: [2],
          left: [0],
          position: "absolute",
          zIndex: -1,
          top: "50%",
          transform: "translateY(-50%)",
          width: "2.5rem",
          height: "2.5rem",
          transition: "width .4s",
        },
        "&:hover": {
          "&:before": {
            width: "100%",
          },
          color: "light",
        },
      },
      "&:before": {
        content: "''",
        display: "block",
        backgroundColor: "primary",
        width: [2],
        height: [2],
        position: "absolute",
        zIndex: -1,
        top: "50%",
        left: [0],
        transform: "translateY(-50%)",
        width: "0rem",
        height: "2.5rem",
        transition: "width .4s",
      },
      "&:hover": {
        "&:before": {
          width: "100%",
        },
      },
    },
    tab: {
      textDecoration: "none",
      mr: 3,
      color: "text",
      "&.active": {
        color: "primary",
        fontWeight: "bold",
      },
    },
  },
  tabs: {
    button: {
      span: {
        "white-space": "nowrap",
        "-webkit-appearance": "none",
        "box-sizing": "border-box",
        "text-align": "center",
        "-webkit-font-smoothing": "antialiased",
        "text-rendering": "optimizelegibility",
        "user-select": "none",
        outline: "none",
        "-webkit-tap-highlight-color": "transparent",
        "box-shadow": "none",
        cursor: "pointer",
        "text-decoration": "none",
        "border-width": "initial",
        "border-style": "none",
        "border-color": "initial",
        "border-image": "initial",
        padding: "10px 1rem",
        "text-size-adjust": "none",
        "text-overflow": "ellipsis",
        "line-height": 1.5,
        "font-size": "0.875rem",
        "font-weight": 600,
        color: "text",
        pl: [3],
        overflow: "hidden",
        position: "relative",
        fontWeight: "500",
        "&:before": {
          content: "'•'",
          fontSize: [4],
          top: "calc(50% - 2px)",
          transform: "translateY(-50%)",
          position: "absolute",
          left: 0,
          mr: "0.5em",
        },
      },
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      a: {
        color: "primary",
        textDecoration: "none",
        "&:hover": {
          textDecoration: "none",
        },
      },
      "--swiper-theme-color": "#00a889",
      ".swiper-container": { pb: 5 },
    },
  },
}

export default theme
